const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  groupData: null
};

export const GroupSlice = createSlice({
  name: 'GroupSlice',
  initialState,
  reducers: {
    setGroupData: (state, action) => {
      state.groupData = action.payload;
    }
  }
});

export const { setGroupData } = GroupSlice.actions;

export default GroupSlice.reducer;
