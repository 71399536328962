export const topBarHeight = 64;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const pageSize = 50;

export const PlansTableColumnDefs = [
  {
    headerName: 'ID',
    field: 'value',
    maxWidth: 200
  },
  {
    headerName: 'Group Name',
    field: 'title',
    minWidth: 200
  },

  {
    headerName: 'Actions',
    cellRenderer: 'ActionButtons',
    minWidth: 135,
    filter: false,
    sortable: false
  }
];
