import { combineReducers, configureStore } from '@reduxjs/toolkit';
import User from './slices/users';
import AlertsSlice from './slices/AlertsSlice';
import GroupSlice from './slices/GroupSlice';
import HelperSlice from './slices/HelperSlice';

const store = configureStore({
  reducer: combineReducers({ User, AlertsSlice, GroupSlice, HelperSlice })
});

export const { dispatch, getState } = store;

export default store;
